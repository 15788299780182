import React, { useState } from 'react';
import styled from 'styled-components';
import api from './Api';
import { useUser } from './UserContext';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 650px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const ModalTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
`;

const ModalBody = styled.div`
  margin-bottom: 20px;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const SubmitButton = styled.button`
  display: block;
  width: 40%;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  background-color: #53736A;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: auto;
`;

const ErrorMessage = styled.p`
  color: red;
  margin-bottom: 10px;
`;

const LocationModal = ({ isOpen, onClose, onSubmit, cities }) => {
  const [selectedLocation, setSelectedLocation] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { fetchUserData } = useUser();

  const handleSubmit = async () => {
    if (!selectedLocation) {
      setErrorMessage('Please select a location.');
      return;
    }

    setIsSubmitting(true);
    setErrorMessage('');

    try {
      const token = localStorage.getItem('token');
      const response = await api.patch('/user', {
        city_id: parseInt(selectedLocation),
        needs_onboarding: false
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      await fetchUserData();
      onSubmit(response.data); // Pass the updated user data
      onClose();
    } catch (error) {
      console.error('Error during submission:', error);
      setErrorMessage('An unexpected error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  const sortedCities = [...cities].sort((a, b) => a.name.localeCompare(b.name));

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalTitle>Choose your location</ModalTitle>
        <ModalBody>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <FormGroup>
            <Label htmlFor="location">Location:</Label>
            <Select
              id="location"
              value={selectedLocation}
              onChange={(e) => setSelectedLocation(e.target.value)}
              disabled={isSubmitting}
            >
              <option value="">Select a location</option>
              {sortedCities.map((city) => (
                <option key={city.id} value={city.id}>
                  {`${city.name}, ${city.state}`}
                </option>
              ))}
            </Select>
          </FormGroup>
        </ModalBody>
        <SubmitButton onClick={handleSubmit} disabled={isSubmitting}>
          {isSubmitting ? 'Updating...' : 'Select Location'}
        </SubmitButton>
      </ModalContent>
    </ModalOverlay>
  );
};

export default LocationModal;