import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logo from '../icons/logo.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #E2E3DE;
`;

const FormWrapper = styled.div`
  width: 400px;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Logo = styled.img`
  width: 100px;
  margin-bottom: 24px;
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 16px;
  text-align: center;
`;

const Description = styled.p`
  font-size: 16px;
  margin-bottom: 24px;
  text-align: center;
`;

const EmailInput = styled.input`
  width: 100%;
  padding: 12px;
  margin-bottom: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
`;

const ResetButton = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const SignUpLink = styled(Link)`
  display: block;
  margin-top: 16px;
  text-align: center;
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <Container>
      <FormWrapper>
        <Logo src={logo} alt="Logo" />
        <Title>Reset Password</Title>
        <Description>
          Enter the email address associated with your account and we'll send you a link to reset your password.
        </Description>
        <form onSubmit={handleSubmit}>
          <EmailInput
            type="email"
            placeholder="Email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <ResetButton type="submit">Reset Password</ResetButton>
        </form>
        <SignUpLink to="/register">Don't have an account? Sign up</SignUpLink>
      </FormWrapper>
    </Container>
  );
};

export default ForgotPasswordPage;
