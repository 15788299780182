import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

let isRefreshing = false;
let failedQueue = [];

const getDeviceId = () => {
  let deviceId = localStorage.getItem('deviceId');
  if (!deviceId) {
    deviceId = 'device_' + Math.random().toString(36).substr(2, 9);
    localStorage.setItem('deviceId', deviceId);
  }
  return deviceId;
};

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  const sessionToken = localStorage.getItem('sessionToken');
  const deviceId = getDeviceId();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (sessionToken) {
    config.headers['X-Session-Token'] = sessionToken;
  }
  config.headers['X-Device-ID'] = deviceId;

  return config;
});

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  
  failedQueue = [];
};

const refreshAccessToken = async () => {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/token/refresh`, {
      refresh: refreshToken,
    });
    const { access, refresh } = response.data;
    localStorage.setItem('token', access);
    if (refresh) {
      localStorage.setItem('refreshToken', refresh);
    }
    return access;
  } catch (error) {
    throw error;
  }
};

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        }).then(token => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          return api(originalRequest);
        }).catch(err => {
          return Promise.reject(err);
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const newToken = await refreshAccessToken();
        processQueue(null, newToken);
        originalRequest.headers['Authorization'] = 'Bearer ' + newToken;

        // After successfully refreshing the token, fetch and update user data
        const userResponse = await api.get('/user', { headers: { Authorization: `Bearer ${newToken}` } });
        localStorage.setItem('user', JSON.stringify(userResponse.data));

        return api(originalRequest);
      } catch (refreshError) {
        processQueue(refreshError, null);
        // Logout the user
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        window.location.href = '/';
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    if (error.response.status === 403) {
      console.error('Forbidden access:', error);
    } else if (error.response.status === 429) {
      console.error('Rate limit reached:', error);
    }

    return Promise.reject(error);
  }
);

export default api;
