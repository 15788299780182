import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext'; 
import googleLogo from '../icons/google.svg';
import api from './Api';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #E2E3DE;
`;

const LoginTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EmailInput = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  width: 300px;
`;

const PasswordInput = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  width: 300px;
`;

const LoginButton = styled.button`
  padding: 10px 20px;
  background-color: #03738C;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%; 
`;

const GoogleLoginButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #FFFFFF;
  color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
`;

const GoogleLogo = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const GoogleButtonText = styled.span`
  font-size: 16px;
`;

const OrDivider = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
  color: #888;
  font-size: 14px;
  width: 100%;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #aaa;
  }

  &::before {
    margin-right: 10px;
  }

  &::after {
    margin-left: 10px;
  }
`;

const SignUpLink = styled.a`
  margin-top: 20px;
  font-size: 14px;
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
  margin-bottom: 20px;
`;

const FormWrapper = styled.div`
  width: 400px;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoginPage = () => {
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { login } = useUser();

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage('');

    const result = await login(email, password);

    if (result.success) {
      navigate('/');
    } else {
      setErrorMessage(result.error);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const response = await api.get(`${process.env.REACT_APP_API_URL}/auth/initiate`);
      window.location.href = response.data.auth_url;
    } catch (error) {
      console.error('Error initiating OAuth2 login:', error);
      setErrorMessage('Failed to initiate Google login. Please try again.');
    }
  };

  return (
    <Container>
      <FormWrapper>
        <Logo src="/logo.svg" alt="Understory Logo" />
        <LoginTitle>Sign in to your account</LoginTitle>
        <LoginForm onSubmit={handleLogin}>
          <EmailInput
            type="email"
            placeholder="Email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <PasswordInput
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginBottom: '10px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox"
                id="rememberMe"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                style={{ marginRight: '5px' }}
              />
              <label htmlFor="rememberMe" style={{ fontSize: '14px' }}>Remember me</label>
            </div>
            <a href="/forgot-password" style={{ fontSize: '14px', color: '#007bff', textDecoration: 'none' }}>
              Forgot password?
            </a>
          </div>
          <LoginButton type="submit">Continue with email</LoginButton>
          {errorMessage && <div style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</div>}
          <SignUpLink href="/register">Don't have an account? Sign up</SignUpLink>
          <OrDivider>or</OrDivider>
          <GoogleLoginButton type="button" onClick={handleGoogleLogin}>
            <GoogleLogo src={googleLogo} alt="Google Logo" />
            <GoogleButtonText>Continue with Google</GoogleButtonText>
          </GoogleLoginButton>
        </LoginForm>
      </FormWrapper>
    </Container>
  );
};

export default LoginPage;
