import React, { useState } from 'react';
import styled from 'styled-components';
import ErrorModal from './ErrorModal';
import { useNavigate, Link } from 'react-router-dom';
import googleLogo from '../icons/google.svg';
import api from './Api';
import { useUser } from './UserContext';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #E2E3DE;
  padding: 0 20px;
`;

const LoginTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EmailInput = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  width: 300px;
`;

const FullNameInput = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  width: 300px;
`;

const PasswordInput = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  width: 300px;
`;

const LoginButton = styled.button`
  padding: 10px 20px;
  background-color: #03738C;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%; 
`;

const GoogleLoginButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #FFFFFF;
  color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
`;

const GoogleLogo = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const GoogleButtonText = styled.span`
  font-size: 16px;
`;

const OrDivider = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
  color: #888;
  font-size: 14px;
  width: 100%;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #aaa;
  }

  &::before {
    margin-right: 10px;
  }

  &::after {
    margin-left: 10px;
  }
`;

const LoginLink = styled(Link)`
  margin-top: 20px;
  font-size: 14px;
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
  margin-bottom: 20px;
`;

const FormWrapper = styled.div`
  width: 400px;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
`;


const RegisterPage = () => {
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { login } = useUser();

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post(`${process.env.REACT_APP_API_URL}/register`, {
        name: fullName,
        email,
        password,
      });
      if (response.status === 201) {
        // Registration successful, now log in the user
        const loginResult = await login(email, password);
        if (loginResult.success) {
          navigate('/');
        } else {
          console.error('Login failed after registration:', loginResult.error);
          setErrorMessage('Registration successful, but login failed. Please try logging in manually.');
          setShowErrorModal(true);
        }
      } 
    } catch (error) {
      if (error.response) {
        console.error('Registration failed:', error.response.data);
        if (error.response.data.error && typeof error.response.data.error === 'object') {
          // Handle the case where error is an object with multiple fields
          const errorMessages = Object.values(error.response.data.error).flat();
          setErrorMessage(errorMessages.join(' '));
        } else if (error.response.data.error) {
          // Handle the case where error is a string
          setErrorMessage(error.response.data.error);
        } else {
          setErrorMessage('Registration failed. Please try again.');
        }
        setShowErrorModal(true);
      } else if (error.request) {
        console.error('No response received:', error.request);
        setErrorMessage('No response received from the server. Please try again.');
        setShowErrorModal(true);
      } else {
        console.error('Error:', error.message);
        setErrorMessage('An unexpected error occurred. Please try again.');
        setShowErrorModal(true);
      }
    }
  };

  const closeErrorModal = () => {
    setShowErrorModal(false);
    setErrorMessage('');
  };

  const handleGoogleLogin = async () => {
    try {
      const response = await api.get(`${process.env.REACT_APP_API_URL}/auth/initiate`);
      window.location.href = response.data.auth_url;
    } catch (error) {
      console.error('Error initiating OAuth2 login:', error);
      setErrorMessage('Failed to initiate Google login. Please try again.');
      setShowErrorModal(true);
    }
  };

  return (
    <Container>
      <FormWrapper>
        <Logo src="/logo.svg" alt="Understory Logo" />
        <LoginTitle>Create your account</LoginTitle>
        <LoginForm onSubmit={handleRegister}>
          <FullNameInput
            type="text"
            placeholder="Full name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            required
          />
          <EmailInput
            type="email"
            placeholder="Email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <PasswordInput
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <LoginButton type="submit">Create account</LoginButton>
          <LoginLink to="/login">Already have an account? Sign in</LoginLink>
          <OrDivider>or</OrDivider>
          <GoogleLoginButton type="button" onClick={handleGoogleLogin}>
            <GoogleLogo src={googleLogo} alt="Google Logo" />
            <GoogleButtonText>Continue with Google</GoogleButtonText>
          </GoogleLoginButton>
        </LoginForm>
      </FormWrapper>
      
      {showErrorModal && (
        <ErrorModal message={errorMessage} onClose={closeErrorModal} />
      )}
    </Container>
  );
};

export default RegisterPage;
