import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { UserProvider } from './components/UserContext';
import Home from './components/Home';
import LoginPage from './components/Login';
import ForgotPasswordPage from './components/ForgotPasswordPage';
import Register from './components/Register';
import Settings from './components/Settings';

const App = () => {
  return (
    <Router>
      <UserProvider>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/settings" element={<Settings />} />
        </Routes>
      </UserProvider>
    </Router>
  );
};

export default App;