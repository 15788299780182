import React, { useState } from 'react';
import styled from 'styled-components';
import stripe from './StripeInstance.js';
import api from './Api';
import { useUser } from './UserContext';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 650px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const ModalTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
`;

const ModalBody = styled.div`
  margin-bottom: 20px;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const SubmitButton = styled.button`
  display: block;
  width: 40%;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  background-color: #53736A;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: auto;
`;

const PlanOptions = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const PlanOption = styled.div`
  flex: 1;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? '#f0f0f0' : '#fff')};

  h3 {
    margin-top: 0;
  }

  p {
    margin-bottom: 10px;
    color: #3E7C59;
    font-weight: bold;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    margin-bottom: 5px;
    color: #888;
    white-space: nowrap;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin-bottom: 10px;
`;

const PlanModal = ({ isOpen, onClose, onSubmit }) => {
  const [selectedPlan, setSelectedPlan] = useState('free');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { user, fetchUserData } = useUser();

  const handleSubscribe = async (plan) => {
    const metadata = {
      plan,
      customer_email: user.email,
    };

    try {
      const token = localStorage.getItem('token');
      const response = await api.post('/create-checkout-session', {
        plan,
        metadata,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const sessionId = response.data.sessionId;
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error('Error creating Checkout session:', error);
      throw error;
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setErrorMessage('');

    try {
      if (selectedPlan === 'free') {
        const token = localStorage.getItem('token');
        await api.patch('/user', { plan: selectedPlan }, {
          headers: { Authorization: `Bearer ${token}` },
        });
        await fetchUserData();
        onSubmit(selectedPlan);
      } else {
        await handleSubscribe(selectedPlan);
      }
    } catch (error) {
      console.error('Error during submission:', error);
      setErrorMessage('An unexpected error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalTitle>Choose your plan with us!</ModalTitle>
        <ModalBody>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <FormGroup>
            <PlanOptions>
              <PlanOption
                selected={selectedPlan === 'free'}
                onClick={() => setSelectedPlan('free')}
              >
                <h3>Free</h3>
                <p>$0/month</p>
                <ul>
                  <li>1 city</li>
                  <li>10 queries per month</li>
                  <li>Queries reset monthly</li>
                </ul>
              </PlanOption>
              <PlanOption
                selected={selectedPlan === 'premium'}
                onClick={() => setSelectedPlan('premium')}
              >
                <h3>Premium</h3>
                <p>$20/month</p>
                <ul>
                  <li>All cities & states</li>
                  <li>1000 queries per month</li>
                  <li>Queries reset monthly</li>
                </ul>
              </PlanOption>
              <PlanOption
                selected={selectedPlan === 'pro'}
                onClick={() => setSelectedPlan('pro')}
              >
                <h3>Pro</h3>
                <p>$50/month</p>
                <ul>
                  <li>All cities & states</li>
                  <li>5,000 queries per month</li>
                  <li>Queries reset monthly</li>
                </ul>
              </PlanOption>
            </PlanOptions>
          </FormGroup>
        </ModalBody>
        <SubmitButton onClick={handleSubmit} disabled={isSubmitting}>
          {isSubmitting ? 'Processing...' : 'Select Plan'}
        </SubmitButton>
      </ModalContent>
    </ModalOverlay>
  );
};

export default PlanModal;
