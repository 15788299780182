import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import api from './Api';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [cities, setCities] = useState([]);
  const [suggestedQuestions, setSuggestedQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const fetchUserData = useCallback(async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      if (token) {
        const [userResponse, citiesResponse] = await Promise.all([
          api.get('/user', { headers: { Authorization: `Bearer ${token}` } }),
          api.get('/cities')
        ]);

        setUser(userResponse.data);
        setCities(citiesResponse.data);

        // Update local storage with new user data
        localStorage.setItem('user', JSON.stringify(userResponse.data));

        if (userResponse.data.city) {
          const questionsResponse = await api.get('/suggested-questions', { headers: { Authorization: `Bearer ${token}` } });
          setSuggestedQuestions(questionsResponse.data.suggested_questions);
        } else {
          setSuggestedQuestions([]);
        }
      } else {
        setUser(null);
        setCities([]);
        setSuggestedQuestions([]);
        localStorage.removeItem('user');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setUser(null);
      setCities([]);
      setSuggestedQuestions([]);
      localStorage.removeItem('user');
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  useEffect(() => {
    const loadInitialData = () => {
      const storedUser = JSON.parse(localStorage.getItem('user'));
      const cachedCities = JSON.parse(localStorage.getItem('cities') || '[]');
      const cachedQuestions = JSON.parse(localStorage.getItem('suggestedQuestions') || '[]');
      const lastFetchTime = parseInt(localStorage.getItem('lastFetchTime') || '0');
      const oneHour = 60 * 60 * 1000;

      if (storedUser) {
        setUser(storedUser);
      }
      if (cachedCities.length > 0) {
        setCities(cachedCities);
      }
      if (cachedQuestions.length > 0 && storedUser && storedUser.city) {
        setSuggestedQuestions(cachedQuestions);
      }

      if (Date.now() - lastFetchTime > oneHour) {
        fetchUserData(); // Fetch fresh data if it's older than 1 hour
      } else {
        setIsLoading(false);
      }
    };

    loadInitialData();
  }, [fetchUserData]);

  const login = useCallback(async (email, password) => {
    try {
      const response = await api.post('/login', { email, password });
      if (response.status === 200) {
        const { token, refresh_token, user } = response.data;
        setUser(user);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken', refresh_token);
        await fetchUserData(); // Fetch all data after login
        return { success: true, user, needsOnboarding: user.needs_onboarding || !user.city };
      } else {
        return { success: false, error: response.data.message };
      }
    } catch (error) {
      console.error('Error during login:', error);
      return { success: false, error: 'An error occurred during login.' };
    }
  }, [fetchUserData]);

  const logout = useCallback(async () => {
    try {
      const response = await api.get(`${process.env.REACT_APP_API_URL}/auth/logout`);
      if (response.data.success) {
        setUser(null);
        setCities([]);
        setSuggestedQuestions([]);
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('cities');
        localStorage.removeItem('suggestedQuestions');
        localStorage.removeItem('lastFetchTime');
        localStorage.removeItem('deviceId');
        navigate('/');
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error during logout:', error);
      return false;
    }
  }, [navigate]);

  return (
    <UserContext.Provider value={{ user, cities, suggestedQuestions, login, logout, isLoading, fetchUserData }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
