import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom'; 
import logo from '../icons/logo.svg';
import { ReactComponent as SettingsIcon } from '../icons/settings.svg';
import { ReactComponent as LogoutIcon } from '../icons/logout.svg';
import { useUser } from './UserContext';

const TopSectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #E2E3DE;
`;

const MenuItem = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  color: #333;
  
  &:hover {
    background: #f0f0f0;
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const Logo = styled.div`
  background-image: url(${logo});
  height: 100px;
  width: 100px;
  background-size: contain;
  background-repeat: no-repeat;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 15px;
  max-height: 15px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

const UserInitial = styled.div`
  background: #2B567F;
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  font-weight: bold;
`;

const UserName = styled.span`
  font-weight: bold;
  color: #333;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100px;
  right: 10px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  z-index: 100;
  min-width: 150px;
`;

const TopSection = ({ authenticated }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const { user, logout } = useUser();

  const handleLogoClick = (e) => {
    e.preventDefault();
    navigate('/');
    window.location.reload();
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleLogout = async () => {
    const success = await logout();
    if (success) {
      navigate('/');
      window.location.reload();
    }
  };

  return (
    <TopSectionContainer>
      <Link to="/" onClick={handleLogoClick} style={{ textDecoration: 'none', color: 'inherit' }}>
        <Logo />
      </Link>

      <div>
        {authenticated && user && (
          <UserInfo onClick={toggleDropdown}>
            <UserInitial>{user.name.slice(0, 1).toUpperCase()}</UserInitial>
            <UserName>{user.name}</UserName>

            {showDropdown && (
              <DropdownMenu>
                <MenuItem onClick={() => navigate('/settings')}>
                  <IconWrapper>
                    <SettingsIcon />
                  </IconWrapper>
                  Settings
                </MenuItem>

                <MenuItem onClick={handleLogout}>
                  <IconWrapper>
                    <LogoutIcon />
                  </IconWrapper>
                  Log out
                </MenuItem>
              </DropdownMenu>
            )}
          </UserInfo>
        )}
      </div>
    </TopSectionContainer>
  );
};

export default TopSection;
