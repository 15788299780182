import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import stripe from './StripeInstance.js';
import TopSection from './TopSection';
import api from './Api';
import { useUser } from './UserContext';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #E2E3DE;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  overflow-y: auto;
  box-sizing: border-box;
  padding-top: 40px;
  padding-bottom: 40px;
`;

const TabContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 600px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
`;

const Tab = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  color: #888;
  border-bottom: none;

  &.active {
    color: #333;
    border-bottom: 2px solid #333;
    font-weight: bold;
  }
`;

const SettingsSection = styled.div`
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px;
`;

const SettingsTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  color: #333333;
`;

const NameInput = styled.input`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  margin-right: 10px;
`;

const UpdateButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  background-color: #3B4C55;
  color: #FFFFFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #4C6573;
  }
`;

const DeleteButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  background-color: #FF5555;
  color: #FFFFFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #FF7777;
  }
`;

const ConfirmationOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const ConfirmationModal = styled.div`
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 20px;
  max-width: 400px;
  text-align: center;
`;

const ConfirmationTitle = styled.h3`
  font-size: 20px;
  margin-bottom: 10px;
`;

const ConfirmationButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  margin: 0 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  background-color: #3B4C55;
  color: #FFFFFF;

  &:hover {
    background-color: #4C6573;
  }

  &.danger {
    background-color: #FF5555;
    color: #FFFFFF;

    &:hover {
      background-color: #FF7777;
    }
  }
`;

const EditButton = styled.button`
  padding: 8px 16px;
  font-size: 14px;
  background-color: #3B4C55;
  color: #FFFFFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  margin-left: auto;

  &:hover {
    background-color: #4C6573;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #333333;
    pointer-events: none;
  }
`;

const Select = styled.select`
  appearance: none;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  background-color: #FFFFFF;
  color: #333333;
  cursor: pointer;
  transition: border-color 0.2s ease-in-out;

  &:focus {
    outline: none;
    border-color: #4C6573;
  }

  &:hover {
    border-color: #4C6573;
  }

  &::-ms-expand {
    display: none;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin-bottom: 10px;
`;

const UploadSection = styled.div`
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UploadTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  color: #333333;
`;

const UploadArea = styled.div`
  border: 2px dashed #CCCCCC;
  border-radius: 10px;
  padding: 40px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #F5F5F5;
  }

  &.dragging {
    background-color: #F5F5F5;
  }
`;

const UploadIcon = styled.i`
  font-size: 48px;
  color: #888888;
  margin-bottom: 10px;
`;

const UploadText = styled.p`
  font-size: 18px;
  color: #888888;
`;

const UploadInput = styled.input`
  display: none;
`;


const UploadedFileList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
  width: 100%;
`;

const UploadedFileItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #CCCCCC;
  width: 100%;
`;

const UploadedFileName = styled.span`
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 20px;
`;

const DeleteFileButton = styled.button`
  padding: 5px 10px;
  font-size: 14px;
  background-color: #FF5555;
  color: #FFFFFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  margin-left: auto;

  &:hover {
    background-color: #FF7777;
  }
`;

const PlanModal = ({ isOpen, onClose, onSubmit, currentPlan }) => {
  const [selectedPlan, setSelectedPlan] = useState(currentPlan);

  const handleSubmit = () => {
    onSubmit(selectedPlan);
    onClose();
  };

  return (
    <ConfirmationOverlay style={{ display: isOpen ? 'flex' : 'none' }}>
      <ConfirmationModal>
        <ConfirmationTitle>Choose your plan</ConfirmationTitle>
        <DropdownContainer>
          <Select value={selectedPlan} onChange={(e) => setSelectedPlan(e.target.value)}>
            <option value="free">Free</option>
            <option value="premium">Premium</option>
            <option value="pro">Pro</option>
          </Select>
        </DropdownContainer>
        <div style={{ marginTop: '20px' }}>
          <ConfirmationButton onClick={handleSubmit}>Update Plan</ConfirmationButton>
          <ConfirmationButton onClick={onClose}>Cancel</ConfirmationButton>
        </div>
      </ConfirmationModal>
    </ConfirmationOverlay>
  );
};

const LocationModal = ({ isOpen, onClose, onSubmit, currentLocation }) => {
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(currentLocation);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await api.get('/cities');
        setCities(response.data);
      } catch (error) {
        console.error('Error fetching cities:', error);
        setErrorMessage('Failed to load cities. Please try again.');
      }
    };

    fetchCities();
  }, []);

  const handleSubmit = () => {
    onSubmit(selectedCity);
    onClose();
  };

  return (
    <ConfirmationOverlay style={{ display: isOpen ? 'flex' : 'none' }}>
      <ConfirmationModal>
        <ConfirmationTitle>Choose your city</ConfirmationTitle>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <DropdownContainer>
          <Select 
            value={selectedCity} 
            onChange={(e) => setSelectedCity(e.target.value)}
          >
            <option value="">Select a city</option>
            {cities.map((city) => (
              <option key={city.id} value={city.id}>
                {`${city.name}, ${city.state}`}
              </option>
            ))}
          </Select>
        </DropdownContainer>
        <div style={{ marginTop: '20px' }}>
          <ConfirmationButton onClick={handleSubmit}>Update City</ConfirmationButton>
          <ConfirmationButton onClick={onClose}>Cancel</ConfirmationButton>
        </div>
      </ConfirmationModal>
    </ConfirmationOverlay>
  );
};

const Settings = () => {
  const [activeTab, setActiveTab] = useState('profile');
  const [name, setName] = useState('');
  const [userPlan, setUserPlan] = useState('');
  const [userCity, setUserCity] = useState('');
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [queriesLeft, setQueriesLeft] = useState(null);
  const [planExpirationDate, setPlanExpirationDate] = useState(null);
  const [featureFlags, setFeatureFlags] = useState({});

  const { user, fetchUserData } = useUser();

  const fetchUploadedFiles = useCallback(async () => {
    if (!featureFlags.can_upload_files) return;

    try {
      const token = localStorage.getItem('token');
      const response = await api.get('/uploaded-files', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUploadedFiles(response.data.files);
    } catch (error) {
      console.error('Error fetching uploaded files:', error);
    }
  }, [featureFlags]);

  useEffect(() => {
    if (user) {
      setName(user.name);
      setUserPlan(user.plan);
      setUserCity(user.city ? `${user.city.name}, ${user.city.state}` : '');
      setFeatureFlags(user.feature_flags || {});
      if (user.feature_flags && user.feature_flags.can_upload_files) {
        fetchUploadedFiles();
      }
      fetchQueriesLeft();
    }
  }, [user, fetchUploadedFiles]);

  const fetchQueriesLeft = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await api.get('/user', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setQueriesLeft(response.data.queries_left);
      setPlanExpirationDate(response.data.plan_expiration_date);
    } catch (error) {
      console.error('Error fetching queries left:', error);
    }
  };

  const handleDeleteFile = async (filename) => {
    try {
      const token = localStorage.getItem('token');
      await api.delete(`/uploaded-files/${filename}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUploadedFiles(uploadedFiles.filter((file) => file !== filename));
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  const handleNameUpdate = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await api.patch(
        '/user',
        { name },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const updatedUser = response.data;
      setName(updatedUser.name);
      localStorage.setItem('user', JSON.stringify(updatedUser));
      fetchUserData(); // Refresh user data in context
      alert('Name updated successfully');
    } catch (error) {
      console.error('Error updating name:', error);
    }
  };

  const handlePlanUpdate = async (selectedPlan) => {
    if (selectedPlan === 'free') {
      try {
        const token = localStorage.getItem('token');
        const response = await api.patch(
          '/user',
          { plan: selectedPlan },
          { headers: { Authorization: `Bearer ${token}` } }
        );

        const updatedUser = response.data;
        setUserPlan(updatedUser.plan);
        localStorage.setItem('user', JSON.stringify(updatedUser));
        fetchUserData(); // Refresh user data in context
        alert('Plan updated successfully');
      } catch (error) {
        console.error('Error updating plan:', error);
      }
    } else {
      const metadata = {
        plan: selectedPlan,
        city: userCity,
        customer_email: user.email,
      };

      try {
        const token = localStorage.getItem('token');
        const response = await api.post('/create-checkout-session', {
          plan: selectedPlan,
          metadata,
        }, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const sessionId = response.data.sessionId;
        await stripe.redirectToCheckout({ sessionId });
      } catch (error) {
        console.error('Error creating Checkout session:', error);
      }
    }
  };

  const handleLocationUpdate = async (selectedCityId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await api.patch(
        '/user',
        { city_id: selectedCityId },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      const updatedUser = response.data;
      setUserCity(`${updatedUser.city.name}, ${updatedUser.city.state}`);
      
      // Update the user object in localStorage
      const storedUser = JSON.parse(localStorage.getItem('user'));
      storedUser.city = updatedUser.city;
      localStorage.setItem('user', JSON.stringify(storedUser));
      
      fetchUserData(); // Refresh user data in context
      alert('City updated successfully');
    } catch (error) {
      console.error('Error updating city:', error);
      alert('Failed to update city. Please try again.');
    }
  };  

  const handleDeleteConfirmation = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteAccount = async () => {
    try {
      const token = localStorage.getItem('token');
      await api.delete('/user', {
        headers: { Authorization: `Bearer ${token}` },
      });
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      fetchUserData(); // This will set the user to null in the context
      window.location.href = '/';
    } catch (error) {
      console.error('Error deleting account:', error);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const filename = file.name;
    const allowedExtensions = ['.txt', '.md', '.html', '.doc', '.docx', '.csv', '.xls', '.xlsx', '.pdf'];
    const fileExtension = filename.slice(filename.lastIndexOf('.')).toLowerCase();
    const maxFileSizeInBytes = 5 * 1024 * 1024; // 5MB
  
    if (!allowedExtensions.includes(fileExtension)) {
      alert('Invalid file type. Only .txt, .md, .html, .doc, .docx, .csv, .xls, .xlsx, and .pdf files are allowed.');
      return;
    }
  
    if (file.size > maxFileSizeInBytes) {
      alert('File size exceeds the maximum limit of 5MB.');
      return;
    }
  
    const formData = new FormData();
    formData.append('file', file);
  
    try {
      const token = localStorage.getItem('token');
      await api.put(`/upload/${filename}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      alert('File uploaded successfully');
      fetchUploadedFiles();
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    setIsDragging(false);

    const file = event.dataTransfer.files[0];
    const filename = file.name;

    const formData = new FormData();
    formData.append('file', file);

    try {
      const token = localStorage.getItem('token');
      await api.put(`/upload/${filename}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      alert('File uploaded successfully');
      fetchUploadedFiles();
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <Wrapper>
      <TopSection authenticated={true} />
      <Container>
        <TabContainer>
          <Tab className={activeTab === 'profile' ? 'active' : ''} onClick={() => setActiveTab('profile')}>
            Profile
          </Tab>
          <Tab className={activeTab === 'plan' ? 'active' : ''} onClick={() => setActiveTab('plan')}>
            Plan
          </Tab>
          {featureFlags.can_upload_files && (
            <Tab className={activeTab === 'upload' ? 'active' : ''} onClick={() => setActiveTab('upload')}>
              Upload
            </Tab>
          )}
        </TabContainer>

        {activeTab === 'profile' && (
          <SettingsSection>
            <SettingsTitle>Profile</SettingsTitle>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
              <NameInput
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <UpdateButton onClick={handleNameUpdate}>Update</UpdateButton>
            </div>
            <DeleteButton onClick={handleDeleteConfirmation}>Delete Account</DeleteButton>
          </SettingsSection>
        )}

        {activeTab === 'plan' && (
          <SettingsSection>
            <SettingsTitle>Plan</SettingsTitle>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
              <p>
                Current Plan: {userPlan} {queriesLeft !== null && `(${queriesLeft} queries left this month)`}
                {planExpirationDate && ` - Expires on ${moment(planExpirationDate).format('MMMM D, YYYY')}`}
              </p>
              <EditButton onClick={() => setShowPlanModal(true)}>Edit</EditButton>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <p>Current City: {userCity || 'Not set'}</p>
              {featureFlags.can_change_city && (
                <EditButton onClick={() => setShowLocationModal(true)}>Edit</EditButton>
              )}
            </div>
          </SettingsSection>
        )}  

        {activeTab === 'upload' && featureFlags.can_upload_files && (
          <UploadSection>
            <UploadTitle>Upload Documents</UploadTitle>
            <UploadArea
                className={isDragging ? 'dragging' : ''}
                onClick={() => document.querySelector('#fileInput').click()}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                <UploadIcon className="fas fa-cloud-upload-alt" />
                <UploadText>Click or drag and drop files here
                  <br /><br />
                  Supported file types: .txt, .md, .html, .doc, .docx, .csv, .xls, .xlsx, .pdf
                  <br />File size limit: 5MB
                </UploadText>
                <UploadInput id="fileInput" type="file" onChange={handleFileUpload} />
            </UploadArea>
            <UploadedFileList>
              {uploadedFiles.map((file) => (
                <UploadedFileItem key={file}>
                  <UploadedFileName>{file}</UploadedFileName>
                  {uploadedFiles.length > 0 && (
                    <DeleteFileButton onClick={() => handleDeleteFile(file)}>
                      Delete
                    </DeleteFileButton>
                  )}
                </UploadedFileItem>
              ))}
            </UploadedFileList>
          </UploadSection>
        )}
      </Container>

      <PlanModal
        isOpen={showPlanModal}
        onClose={() => setShowPlanModal(false)}
        onSubmit={handlePlanUpdate}
        currentPlan={userPlan}
      />
      
      <LocationModal
        isOpen={showLocationModal}
        onClose={() => setShowLocationModal(false)}
        onSubmit={handleLocationUpdate}
        currentLocation={userCity}
      />

      {showDeleteModal && (
        <ConfirmationOverlay>
          <ConfirmationModal>
            <ConfirmationTitle>Are you sure you want to delete your account?</ConfirmationTitle>
            <p>This action cannot be undone.</p>
            <div style={{ marginTop: '20px' }}>
              <ConfirmationButton className="danger" onClick={handleDeleteAccount}>
                Delete
              </ConfirmationButton>
              <ConfirmationButton onClick={handleDeleteCancel}>
                Cancel
              </ConfirmationButton>
            </div>
          </ConfirmationModal>
        </ConfirmationOverlay>
      )}
    </Wrapper>
  );
};

export default Settings;